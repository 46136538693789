<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-07-14 16:53:22
 * @LastEditors: hutian
 * @LastEditTime: 2021-08-07 15:01:05
-->
<template>
  <div class="quota_audio">
    <a-modal v-model="visible" style="top: 8px" :width="600" :maskClosable="false" title="审核配额" :confirmLoading="confirmLoading" @ok="onSubmit">
      <a-form-model ref="form" layout="horizontal" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" :model="rowData" :rules="formRule">
      <a-form-model-item label="申请配额">
        <a-input placeholder="申请配额" v-model="rowData.temporaryQuota"></a-input>
      </a-form-model-item>
        <a-form-model-item label="是否通过">
          <a-radio-group v-model="rowData.status" @change="onStatusChange">
            <a-radio :value="2"> 通过 </a-radio>
            <a-radio :value="3"> 拒绝 </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="有效期" v-if="statusRadio == 2" prop="startingTime">
          <DateRange :startTime.sync="rowData.startingTime" :endTime.sync="rowData.expireDate"></DateRange>
        </a-form-model-item>
        <a-form-model-item label="备注">
          <a-textarea placeholder="备注" v-model="rowData.auditReason"></a-textarea>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import {selectByIdDealerTemporaryQuotaRecord} from "@/views/dealer_temporary_quota_record/api/DealerTemporaryQuotaRecordApi";
export default {
  name: '',
  data() {
    return {
      statusRadio: 2,
      visible: false,
      rowData: {},
      confirmLoading: false,
      // 表单验证
      formRule: {
        startingTime: [{ required: true, message: '请选择生效时间', trigger: 'change' }],
      },
    }
  },

  computed: {},

  created() {},

  methods: {
    show(row, name) {
      this.visible = true
      this.rowData = {}
      selectByIdDealerTemporaryQuotaRecord(row.id).then(res => {
        this.rowData = res.body
        this.$set(this.rowData, 'status', 2)
      })
    },
    onStatusChange(val) {
      this.statusRadio = val.target.value
    },
    
    disabledDate(current) {
      return current && current == moment()
    },

    // 提交审核
    onSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }
        this.rowData.status = this.statusRadio
        this.confirmLoading = true
        this.axios.post(`/api/base/customer/dealerTemporaryQuotaRecord/updateData`, this.rowData).then(res => {
          this.$message.success(res.message)
          this.confirmLoading = false
          this.visible = false
          this.$emit('reload')
        }).catch(err => {
          this.confirmLoading = false
          // this.visible = false
        })
      })
    }
  },
}
</script>

<style lang='scss' scoped>
</style>